<template>
  <div class="querycommunity">
    <LiefengContent>
      <template v-slot:title>{{ `信息发布操作日志记录(近500条)` }}</template>
      <template v-slot:toolsbarRight>
        <Form :label-colon="true" :inline="true" class="search">
          <FormItem>
            <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.title"
                placeholder="请输入信息名称"
                style="margin-right:10px;width: 150px"
            />
          </FormItem>
          <FormItem>
            <Input
                :maxlength="20"
                enter-button
                v-model.trim="searchData.staffName"
                placeholder="请输入员工姓名"
                style="margin-right:10px;width: 150px"
            />
          </FormItem>
          <FormItem>
            <Select transfer v-model="searchData.operateType" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择操作类型">
              <Option v-for="data in dictList" :value="data.dictKey" :key="data.dictValue">{{ data.dictValue }}</Option>
            </Select>
          </FormItem>
          <Button type="primary" icon="ios-search" @click="request" style="margin-right: 10px">查询</Button>
        </Form>
      </template>

      <template v-slot:contentArea>
        <LiefengTable
          :talbeColumns="columns"
          :tableData="data" 
          :hidePage="true">
        </LiefengTable>
      </template>
    </LiefengContent>
  </div>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengCascaderCity from "@/components/LiefengCascaderCity"
import LiefengTable from "@/components/LiefengTable"

export default {
  components: {
    LiefengContent,
    LiefengCascaderCity,
    LiefengTable
  },
  destroyed() {
    this.data = []
  },
  data() {
    return {
      dict:[],
      dictList:[],
      searchData:{
        title:'',
        staffName:'',
        operateType:''
      },
      columns: [
        {title: '信息ID', key: 'informationId'},
        {title: '信息编码', key: 'code'},
        {title: '信息标题', key: 'title'},
        {title: '栏目编码', key: 'columnCode'},
        {title: '操作人', key: 'staffName'},
        {title: 'ip地址', key: 'ipAddr'},
        {title: '操作类型', key: 'operateType'},
        {title: '操作时间', key: 'gmtCreate'},
      ],
      data: []
    }
  },
  created() {
    this.getDictTyet("INFORMATION_OP_LOG","dict")
    this.request();
  },
  methods: {
    async request() {
      this.$get("/gateway/syinfopublish/api/pc/information/opLog/queryByPage",{
        title:this.searchData.title,
        staffName:this.searchData.staffName,
        operateType:this.searchData.operateType,
        page: 1,
        pageSize: 500,
      }).then(res => {
        for(let i = 0;  i < res.dataList.length; i++) {
          res.dataList[i].gmtCreate = this.$core.formatDate(new Date(res.dataList[i].gmtCreate),"yyyy-MM-dd hh:mm:ss")
          res.dataList[i].operateType = this.dict.get(res.dataList[i].operateType);
        }
        this.data = res.dataList
      })
    },
    async getDictTyet(dictType,list){
      this.$get("/dictlist/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
        dictType,
        appCode:""
      }).then(res => {
        if(res.code == 200) {
          var map = new Map();
          res.dataList.forEach(item=>{
            map.set(item.dictKey,item.dictValue)
          })
          this.dict = map;
          this.dictList = res.dataList;
        }
      })
    },
  }
}
</script>

<style scoped lang="less">
  .querycommunity {
    /deep/ .table > .ivu-table > .ivu-table-tip > table > tbody > tr > td{
      height: calc(100vh - 150px);
      border-left: 1px solid #f8f8f9;
      border-bottom: 1px solid #f8f8f9;
      border-right: 1px solid #f8f8f9;
    }
  }
</style>